import React from 'react';
import './Footer.css'

function Footer() {

    return (
        <div className='footerContainer'>
            <div className='footerText'>
                Thanks for reading
            </div>
        </div>
    );
};

export default Footer;